import { SET_INTERNET_PAGE_INFO, SET_INTERNET_PAGE_TARIFFS_FOR_CHANGE } from '@/apps/admin/store/mutation-types';
import { isset } from '@/utils/functions';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    taxesArray: {},
    speedLimitTypes: {},
    prioritiesArray: {},
    burstTypesArray: {},
    customerLabels: {},
    tariffsForChangeArray: {},
    customerBillingTypes: {},
    excludeTrafficClasses: {},
    className: '',
    attributesMeta: {},
    customPeriod: {},
    afAttributesMeta: {},
    model: {},
    transactionsCategories: {},
    isSetTransactionCategory: false,
    customerCategories: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadAddPageInfo({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/tariffs/internet--get-form-page-info',
                method: 'GET',
                success: (response) => {
                    commit(SET_INTERNET_PAGE_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    loadEditPageInfo({ commit }, id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/tariffs/internet--get-form-page-info?id=${id}`,
                method: 'GET',
                success: (response) => {
                    commit(SET_INTERNET_PAGE_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

const mutations = {
    [SET_INTERNET_PAGE_INFO](state, info) {
        state.taxesArray = info.taxesArray;
        state.speedLimitTypes = info.speedLimitTypes;
        state.prioritiesArray = info.prioritiesArray;
        state.burstTypesArray = info.burstTypesArray;
        state.customerLabels = info.customerLabels;
        state.tariffsForChangeArray = info.tariffsForChangeArray;
        state.customerBillingTypes = info.customerBillingTypes;
        state.excludeTrafficClasses = info.excludeTrafficClasses;
        state.attributesMeta = info.attributesMeta.attributes;
        state.className = info.attributesMeta.className;
        state.transactionsCategories = info.transactionsCategories;
        state.isSetTransactionCategory = info.isSetTransactionCategory;
        state.customerCategories = info.customerCategories;
        state.customPeriod = info.customPeriod;

        if (isset(info, ['attributesMeta', 'additional_attributes'])) {
            state.afAttributesMeta = info.attributesMeta.additional_attributes;
        }
        if (isset(info, 'model')) {
            state.model = info.model;
        }
    },
    [SET_INTERNET_PAGE_TARIFFS_FOR_CHANGE](state, tariffsForChange) {
        state.tariffsForChangeArray = tariffsForChange;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
