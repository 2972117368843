import {
    SET_TRANSACTION_MODAL_INFO, CLEAR_TRANSACTION_MODAL_INFO,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    isNewRecord: false,
    model: {},
    showToInvoice: false,
    needShowPlansSelect: false,
    readOnly: false,
    taxName: 'VAT',
    taxesArray: {},
    categoriesArray: {},
    typesArray: {},
    attributesMeta: {},
    afAttributesMeta: {},
    taxTotal: 0,
    totalWithoutTax: 0,
    totalWithTax: 0,
    vatPercent: 0,
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, { customer_id, transaction_id }) {
        let data = {
            customer_id,
        };
        if (transaction_id) {
            data.transaction_id = transaction_id;
        }

        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/customers/billing/transactions--get-modal-info',
                method: 'GET',
                data,
                success: (response) => {
                    commit(SET_TRANSACTION_MODAL_INFO, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_TRANSACTION_MODAL_INFO](state, info) {
        for (let key in info) {
            if (key === 'attributesMeta') {
                state[key] = info[key].attributes;
                state.afAttributesMeta = info[key].additional_attributes;
                continue;
            }
            state[key] = info[key];
        }
    },
    [CLEAR_TRANSACTION_MODAL_INFO](state) {
        state.model = {};
        state.taxTotal = 0;
        state.totalWithoutTax = 0;
        state.totalWithTax = 0;
        state.showToInvoice = false;
        state.isNewRecord = false;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
