var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-form',{ref:"form",staticClass:"form-horizontal",attrs:{"id":'admin_tariffs_one_time_form_' + _vm.form,"url":_vm.formUrl,"method":"POST","data":_vm.model,"class-name":_vm.className,"attributes-meta":_vm.attributesMeta,"af-attributes-meta":_vm.afAttributesMeta},on:{"success":_vm.onFormSuccess,"error":_vm.onFormError}},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('title'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"type":"text","params":{
                    options: {
                        name: 'OneTimeTariffs[title]',
                    }
                },"meta":_vm.attributesMeta.title},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('service_description'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"type":"text","params":{
                    options: {
                        name: 'OneTimeTariffs[service_description]',
                    },
                },"meta":_vm.attributesMeta.service_description},model:{value:(_vm.model.service_description),callback:function ($$v) {_vm.$set(_vm.model, "service_description", $$v)},expression:"model.service_description"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('enabled'))+" ")]),_c('div',{staticClass:"col-md-8 d-flex"},[_c('x-complex-input',{attrs:{"type":"checkbox","params":{
                    label: _vm.availableForServicesLabel,
                    options: {
                        class: 'availible-for-services',
                        name: 'OneTimeTariffs[enabled]',
                    }
                },"meta":_vm.attributesMeta.available_for_services},model:{value:(_vm.model.enabled),callback:function ($$v) {_vm.$set(_vm.model, "enabled", $$v)},expression:"model.enabled"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('price'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"type":"number","params":{
                    allowEmpty: true,
                    numberType: 'decimal',
                    options: {
                        name: 'OneTimeTariffs[price]',
                    },
                },"meta":_vm.attributesMeta.price},model:{value:(_vm.model.price),callback:function ($$v) {_vm.$set(_vm.model, "price", $$v)},expression:"model.price"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('partners_ids'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"type":"multiple-select","params":{
                    options: {
                        options: _vm.partners,
                        name: 'OneTimeTariffs[partners_ids]',
                        required: true,
                    },
                },"meta":_vm.attributesMeta.partners_ids},model:{value:(_vm.model.partners_ids),callback:function ($$v) {_vm.$set(_vm.model, "partners_ids", $$v)},expression:"model.partners_ids"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('with_vat'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"type":"checkbox","params":{
                    options: {
                        name: 'OneTimeTariffs[with_vat]',
                    },
                },"meta":_vm.attributesMeta.with_vat},model:{value:(_vm.model.with_vat),callback:function ($$v) {_vm.$set(_vm.model, "with_vat", $$v)},expression:"model.with_vat"}})],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('vat_percent'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"type":"select","name":"vatPercent","params":{
                    options: {
                        options: _vm.taxesArray,
                        name: 'OneTimeTariffs[vat_percent]',
                    },
                },"meta":_vm.attributesMeta.vat_percent},model:{value:(_vm.model.vat_percent),callback:function ($$v) {_vm.$set(_vm.model, "vat_percent", $$v)},expression:"model.vat_percent"}})],1)]),(_vm.isSetTransactionCategory)?_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.getAttributeLabel('transaction_category_id'))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-complex-input',{attrs:{"type":"select","params":{
                    options: {
                        options: _vm.transactionsCategories,
                        name: 'OneTimeTariffs[transaction_category_id]',
                    },
                },"meta":_vm.attributesMeta.transaction_category_id},model:{value:(_vm.model.transaction_category_id),callback:function ($$v) {_vm.$set(_vm.model, "transaction_category_id", $$v)},expression:"model.transaction_category_id"}})],1)]):_vm._e(),_vm._l((_vm.afsForAdding),function(afAttribute,name){return _c('div',{key:name,staticClass:"row"},[_c('label',{staticClass:"col-md-4 col-form-label"},[_vm._v(" "+_vm._s(afAttribute.title)+" ")]),_c('div',{staticClass:"col-md-8"},[_c('x-additional-field-input',{attrs:{"type":afAttribute.type,"search":false,"params":afAttribute},model:{value:(_vm.model.additional_attributes[name]),callback:function ($$v) {_vm.$set(_vm.model.additional_attributes, name, $$v)},expression:"model.additional_attributes[name]"}})],1)])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"btn btn-primary pull-right",attrs:{"id":'admin_tariff_one_time_button' + (_vm.form === 'add' ? '_add' : '_edit'),"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.form.submit()}}},[_vm._v(" "+_vm._s(_vm.$t('tariffs', 'Save'))+" ")]),_c('a',{staticClass:"btn btn-cancel pull-right",on:{"click":_vm.backToList}},[_vm._v(_vm._s(_vm.$t('tariffs', 'Back to list')))])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }