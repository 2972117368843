<template>
    <div class="content clearfix active">
        <x-preloader-block :loading="loading">
            <div class="splynx-top-nav">
                <x-breadcrumbs :items="breadcrumbs" />
            </div>

            <div class="spl-page-panel">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="card">
                            <div class="card-body">
                                <one-time-plans-form
                                    ref="form"
                                    :form="type"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </x-preloader-block>
    </div>
</template>

<script>
import { XBreadcrumbs } from '@/components/base/XBreadcrumbs';
import { XPreloaderBlock } from '@/components/common/XPreloaderBlock';
import { mapActions, mapGetters } from 'vuex';
import OneTimePlansForm from '@/apps/admin/views/admin/tariffs/one-time/OneTimePlansForm';
import TariffAddEditMixins from '@/apps/admin/views/admin/tariffs/mixins/tariff-add-edit-mixins';

export default {
    name: 'OneTimePlansAddEdit',
    mixins: [TariffAddEditMixins],
    data() {
        return {
            loading: true,
        };
    },
    created() {
        Promise.all([
            this.loadPageInfo(),
            this.loadPartners(),
        ]).finally(() => {
            this.loading = false;
        });
    },
    methods: {
        ...mapActions('one_time_tariffs', [
            'loadAddPageInfo',
            'loadEditPageInfo',
        ]),
    },
    computed: {
        ...mapGetters('one_time_tariffs', [
            'model',
        ]),
    },
    components: {
        OneTimePlansForm,
        XBreadcrumbs,
        XPreloaderBlock,
    },
};
</script>
