import {
    CHANGE_FUTURE_BLOCK_OVERVIEW_TAB_INFO,
    CLEAR_OVERVIEW_TAB_INFO,
    SET_OVERVIEW_TAB_INFO,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    typeOverview: false,
    typePrepaid: false,
    typePrepaidMonthly: false,
    isEntryPointsExist: false,
    hasActiveServices: false,
    invertAccountBalance: false,
    needCreateInvoiceAfterPayment: false,
    model: {},
    attributesMeta: {},
    partnerId: 0,
    customerId: 0,
    futureBlockInfo: {},
    adminCan: {
        toBill: false,
        calendar: false,
        previewReminder: false,
        cancelToBill: false,
        paymentAccountsIndex: false,
        extendExpiration: false,
        cancelLastChargeIsEnabled: false,
    },
    reminderBlock: {
        days: [],
        labels: [],
        types: {},
        count: 0,
        recurringNextDate: [
            {
                date: '',
                label: '',
                format: '',
            },
        ],
        isStaticDay: false,
    },
    paymentAccountData: '',
    billingInfo: {
        types: {},
        paymentMethods: {},
        blockingDates: {},
        dates: {},
        disableValueBlockingPeriod: 0,
        defaultPartnerPercent: '0',
        datesWithoutDisabled: {},
        datesForProformaInvoice: {},
        periods: {},
        requestTypes: {},
    },
    customerBlockingInfo: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageData({ commit }, customer_id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/overview--get-page-data?id=${customer_id}`,
                method: 'GET',
                success: (response) => {
                    commit(SET_OVERVIEW_TAB_INFO, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    changeFutureBlockInfo({ commit }, info) {
        commit(CHANGE_FUTURE_BLOCK_OVERVIEW_TAB_INFO, info);
    },
};

const mutations = {
    [SET_OVERVIEW_TAB_INFO](state, info) {
        for (let key in info) {
            if (key == 'customerId' || key == 'partnerId') {
                state[key] = Number(info[key]);
                continue;
            }
            state[key] = info[key];
        }
    },
    [CHANGE_FUTURE_BLOCK_OVERVIEW_TAB_INFO](state, info) {
        state.futureBlockInfo = info;
    },
    [CLEAR_OVERVIEW_TAB_INFO](state) {
        state.typeOverview = false;
        state.typePrepaid = false;
        state.typePrepaidMonthly = false;
        state.blockInNextBillingCycle = false;
        state.isEntryPointsExist = false;
        state.hasActiveServices = false;
        state.needCreateInvoiceAfterPayment = false;
        state.model = {};
        state.attributesMeta = {};
        state.partnerId = 0;
        state.customerId = 0;
        state.futureBlockInfo = {};
        state.adminCan = {
            toBill: false,
            calendar: false,
            previewReminder: false,
            cancelToBill: false,
            paymentAccountsIndex: false,
            extendExpiration: false,
        };
        state.reminderBlock = {
            days: [],
            labels: [],
            types: {},
            count: 0,
            recurringNextDate: [
                {
                    date: '',
                    label: '',
                    format: '',
                },
            ],
            isStaticDay: false,
        };
        state.paymentAccountData = '';
        state.billingInfo = {
            types: {},
            paymentMethods: {},
            blockingDates: {},
            dates: {},
            disableValueBlockingPeriod: 0,
            defaultPartnerPercent: '0',
            datesWithoutDisabled: {},
            datesForProformaInvoice: {},
            periods: {},
            requestTypes: {},
            cancelLastChargeIsEnabled: false,
        };
        state.requestTypes = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
