import { SET_RECURRING_PAGE_TARIFFS_FOR_CHANGE, SET_RECURRING_TARIFFS_PAGE_INFO } from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { toCamelCase } from '@/utils/functions';

const state = {
    model: {},
    className: '',
    attributesMeta: {},
    afAttributesMeta: {},
    customerLabels: {},
    taxesArray: {},
    tariffsForChange: {},
    billingTypes: {},
    customPeriod: {},
    transactionsCategories: {},
    isSetTransactionCategory: false,
    customerCategories: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadAddPageInfo({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/tariffs/recurring--get-form-page-info',
                success: (response) => {
                    commit(SET_RECURRING_TARIFFS_PAGE_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    loadEditPageInfo({ commit }, id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/tariffs/recurring--get-form-page-info?id=${id}`,
                success: (response) => {
                    commit(SET_RECURRING_TARIFFS_PAGE_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    setRecurringPageTariffsForChange({ commit }, data) {
        commit(SET_RECURRING_PAGE_TARIFFS_FOR_CHANGE, data);
    },
};

const mutations = {
    [SET_RECURRING_TARIFFS_PAGE_INFO](state, info) {
        for (let key in info) {
            state[toCamelCase(key)] = info[key];
        }
        state.attributesMeta = info.attributes_meta.attributes;
        state.className = info.attributes_meta.className;
        state.afAttributesMeta = info.attributes_meta.additional_attributes;
    },
    [SET_RECURRING_PAGE_TARIFFS_FOR_CHANGE](state, tariffsForChange) {
        state.tariffsForChange = tariffsForChange;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
