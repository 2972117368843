<template>
    <div class="main">
        <div class="container center-content flex-column">
            <div class="row welcome-holder">
                <div class="col-md-12">
                    <h2 class="text-center">
                        Welcome to Splynx
                    </h2>
                    <div class="card mt-24">
                        <div class="card-header ps-24 pe-24">
                            <h4>Enter license code:</h4>
                        </div>
                        <div class="card-body p-24 pt-12">
                            <form
                                v-show="!loading"
                                id="splynx_license_form"
                                method="GET"
                                class="fix-height"
                                @submit.prevent="setLicenseSubmit"
                            >
                                <div class="row">
                                    <label class="col-md-12">
                                        License
                                    </label>

                                    <div class="col-md-12">
                                        <input
                                            id="admin_license_save_button"
                                            type="submit"
                                            value="Save"
                                            class="btn btn-primary pull-right"
                                        >
                                        <input
                                            id="license_code"
                                            type="text"
                                            class="form-control input-sm license-input"
                                            name="license"
                                            value=""
                                        >
                                        <small
                                            class="color-danger"
                                            v-html="errorMessage"
                                        />
                                    </div>
                                </div>
                            </form>
                            <div
                                v-show="loading"
                                class="row fix-height"
                            >
                                <div class="col-md-12 center-content">
                                    <x-bootstrap-spinner
                                        type="primary"
                                        size="md"
                                    />
                                </div>
                            </div>

                            <div class="row mt-24">
                                <div class="col-md-12">
                                    <p class="color-dark">
                                        You can get license on this url: <a
                                            target="_blank"
                                            class="btn btn-link"
                                            href="https://splynx.com/get-started/?utm_campaign=license-inside"
                                        >https://splynx.com/get-started/</a>
                                    </p>
                                    <p class="color-dark">
                                        Contact if needed:<br>
                                        <a
                                            class="btn btn-link"
                                            href="mailto:info@splynx.com"
                                        >info@splynx.com</a><br>
                                        <a
                                            class="btn btn-link"
                                            href="https://splynx.com/"
                                        >https://splynx.com/</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LicenseChecker from '@/apps/admin/services/license';
import { XBootstrapSpinner } from '@/components/common/XBootstrapSpinner';

export default {
    name: 'AdminWelcome',
    components: {
        XBootstrapSpinner,
    },
    data() {
        return {
            loading: false,
            errorMessage: '',
        };
    },
    computed: {},
    mounted() {
        $(() => {
            $('#license_code').focus();
        });
    },
    methods: {
        setLicenseSubmit() {
            let _this = this;
            $.ajax({
                type: 'GET',
                url: '/admin/tools--set-license',
                dataType: 'json',
                data: $('#splynx_license_form').serialize(),
                beforeSend() {
                    _this.loading = true;
                },
                success(data) {
                    _this.loading = false;
                    if (data.result) {
                        alert('Success! Page will be reloaded in 2 seconds!');
                        setTimeout(() => {
                            LicenseChecker.reloadLicenseRoute();
                        }, 2000);
                    } else if (data.message) {
                        _this.errorMessage = data.message[0];
                    }
                },
            });
            return false;
        },
    },
};
</script>
