<template>
    <ul
        ref="headerNavigationHolder"
        class="navigation"
    >
        <li v-if="!mobileWidth && showDeploymentGuideButton">
            <button
                id="buttonDeploymentGuide"
                type="button"
                class="btn btn-primary"
                :title="$t('common', 'Deployment guide')"
                @click="loadDeploymentGuide"
                v-text="$t('common', 'Deployment guide')"
            />
        </li>

        <li
            v-if="!mobileWidth"
            v-show="isHeaderShortcuts"
            class="dropdown"
            data-test-selector="quick-addition"
            :title="$t('common', 'Quick addition')"
        >
            <a
                href="javascript:void(0)"
                role="button"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
            >
                <i class="icon-ic_fluent_add_24_regular" />
            </a>

            <div class="dropdown-menu dropdown-menu-end">
                <div
                    v-for="(item, index) in headerShortcuts"
                    :key="index"
                    class="dropdown-item"
                >
                    <router-link
                        :to="item.url"
                        :title="item.title"
                        v-text="item.title"
                    />
                </div>
            </div>
        </li>

        <li>
            <a
                data-hotkey="/,s"
                :title="$t('common', 'Search')"
                href="javascript:void(0)"
                data-test-selector="search"
                @click="loadGlobalSearch()"
            >
                <i class="icon-ic_fluent_search_24_regular" />
            </a>
        </li>

        <li
            v-if="isShowHelp"
            id="help-dropdown"
        >
            <a
                href="javascript:void(0)"
                :title="$t('common', 'Help and Support')"
                data-test-selector="help-and-support"
                @click="loadSupportPanel()"
            >
                <i class="icon-ic_fluent_chat_help_24_regular" />
            </a>
        </li>

        <li id="admin_right_help_panel_open_button">
            <a
                href="javascript:void(0);"
                data-hotkey="d"
                :title="$t('common', 'Documentation')"
                data-test-selector="documentation"
                @click="loadHelpPanel()"
            >
                <i class="icon-ic_fluent_book_information_24_regular" />
            </a>
        </li>

        <li class="notification-main">
            <a
                id="notifications"
                href="javascript:void(0);"
                :title="$t('common', 'Notifications')"
                data-test-selector="notifications"
                @click="loadNotificationsPanel()"
            >
                <i class="icon-ic_fluent_alert_24_regular" />
                <div
                    :style="{ opacity: notificationsList.length ? '1' : '0' }"
                    class="noty-manager-bubble"
                >{{ notificationsList.length }}</div>
            </a>
            <div
                id="notification-list"
                ref="notifications"
            />
        </li>

        <li
            v-if="!mobileWidth"
            class="dropdown"
        >
            <a
                class="dropdown-toggle custom-chevron"
                data-bs-toggle="dropdown"
                href="javascript:void(0)"
                data-test-selector="admin-dropdown-button"
            >
                <span class="avatar-admin">
                    <img
                        v-if="admin.photo_url !== ''"
                        :src="admin.photo_url"
                        :alt="admin.login"
                    >
                    <i
                        v-else
                        class="icon-ic_fluent_person_24_regular"
                    />
                </span>
                <span
                    class="name"
                    v-html="admin.name"
                />
            </a>
            <div
                class="dropdown-menu admin-dropdown dropdown-menu-end"
                data-test-selector="admin-dropdown"
            >
                <div class="admin-dropdown-item">
                    <span class="avatar-admin">
                        <img
                            v-if="admin.photo_url !== ''"
                            :src="admin.photo_url"
                            :alt="admin.login"
                        >
                        <i
                            v-else
                            class="icon-ic_fluent_person_24_regular"
                        />
                    </span>
                    <span class="admin-name">
                        <span
                            class="name"
                            v-html="admin.login"
                        />
                        <span
                            class="status"
                            v-html="admin.role_title"
                        />
                    </span>
                </div>
                <div class="dropdown-item">
                    <a
                        href="/admin/profile"
                        data-test-selector="admin-profile"
                    >
                        <span class="icon-wrap">
                            <i class="icon-ic_fluent_person_24_regular" />
                        </span>
                        {{ $t('profile', 'Profile') }}
                    </a>
                </div>
                <div
                    v-show="isShowTimeout"
                    class="dropdown-item"
                >
                    <span>
                        {{ $t('site', 'Logout after') }}:
                        <span id="auto_logout">{{ admin.timeout }}</span> <!-- maybe need something else -->
                    </span>
                </div>
                <div class="dropdown-item">
                    <a
                        data-test-selector="admin-logout"
                        @click.prevent="logoutClickHandler"
                    >
                        <span class="icon-wrap">
                            <i class="icon-ic_fluent_sign_out_24_regular" />
                        </span>
                        {{ $t('site', 'Logout') }}
                    </a>
                </div>
            </div>
        </li>

        <li v-if="mobileWidth">
            <a
                data-test-selector="profile"
                @click.prevent="toProfile"
            >
                <span class="icon-wrap">
                    <i class="icon-ic_fluent_person_24_regular" />
                </span>
            </a>
        </li>

        <li
            v-if="mobileWidth"
            data-test-selector="logout"
            @click.prevent="logoutClickHandler"
        >
            <a>
                <span class="icon-wrap">
                    <i class="icon-ic_fluent_sign_out_24_regular" />
                </span>
            </a>
        </li>
    </ul>
</template>

<script>
import { XGlobalSearch } from '@/components/base/XGlobalSearch';
import XHelpPanel from '@/components/base/XHelpPanel';
import XSupportPanel from '@/components/base/XSupportPanel';
import XNotificationsPanel from '@/components/base/XNotificationPanel';
import XDeploymentGuide from '@/components/base/XDeploymentGuide';
import { mapActions, mapGetters } from 'vuex';
import { empty } from '@/utils/functions';
import { install as installHotkeys } from '@github/hotkey';

export default {
    name: 'XHeaderList',
    data() {
        return {};
    },
    mounted() {
        for (const el of document.querySelectorAll('[data-hotkey]')) {
            installHotkeys(el);
        }
        if (!this.mobileWidth) {
            this.$nextTick(() => {
                let menuHolder = this.$parent?.$parent?.$children?.find((item) => item.$el.className === 'side-wrap');
                this.addRefs(this.$refs);
                this.addRefs(menuHolder?.$refs);
                this.showDeploymentGuide();
            });
        }
        splynx_event_bus.on('after_switch_page', this.closeDropdown);
    },
    methods: {
        closeDropdown() {
            if ($('.splynx-header .dropdown-toggle').hasClass('show')) {
                $('.splynx-header .dropdown-toggle.show').dropdown('toggle');
            }
        },
        loadGlobalSearch() {
            let sidebarOptions = {
                title: t('common', 'Search'),
                props: {
                    availableSearchEntityList: this.searchEntities,
                    appMountedCallback: (component) => {
                        component.setFocusIntoSearchInput();
                        document.addEventListener('keydown', component.processKeyboard);
                    },
                    appDestroyedCallback: (component) => {
                        document.removeEventListener('keydown', component.processKeyboard);
                    },
                    openSearch: this.openByShortcut,
                },
                component: XGlobalSearch,
            };
            this.showSidebar(sidebarOptions);
        },
        loadSupportPanel() {
            let sidebarOptions = {
                title: t('common', 'Splynx Support (New ticket)'),
                props: {
                    src: 'https://billing.splynx.com/admin/feedback?widgetType=embedded&formTitle=Support%20and%20help&submitTitle=Send&submitThanks=Thanks%20for%20feedback&attachFile=1',
                },
                component: XSupportPanel,
            };
            this.showSidebar(sidebarOptions);
        },
        loadHelpPanel() {
            let sidebarOptions = {
                title: t('common', 'Documentation'),
                props: {
                    src: '/admin/help',
                    ref: 'contentComponent',
                    lang: window.spl_config.language,
                    showExportLink: true,
                },
                component: XHelpPanel,
            };
            this.showSidebar(sidebarOptions);
        },
        loadNotificationsPanel() {
            let sidebarOptions = {
                title: t('common', 'Notifications'),
                props: {},
                component: XNotificationsPanel,
            };
            this.showSidebar(sidebarOptions);
        },
        loadDeploymentGuide() {
            let sidebarOptions = {
                title: t('common', 'Deployment guide'),
                subtitle: t('common', 'Recommended steps for a successful deployment.'),
                props: {},
                component: XDeploymentGuide,
            };
            this.showSidebar(sidebarOptions);
        },
        logoutClickHandler() {
            window.logout('/admin/logout');
        },
        toProfile() {
            this.$router.push('/admin/profile');
        },
        closeMobileMenu() {
            this.showMobileMenu(false);
        },
        ...mapActions('menu_store', [
            'showMobileMenu',
        ]),
        ...mapActions('sidebar', [
            'showSidebar',
        ]),
        ...mapActions('on_boarding', [
            'addRefs', 'showDeploymentGuide',
        ]),
    },
    computed: {
        isShowHelp() {
            return this.admin.role_name === 'super-administrator';
        },
        isShowTimeout() {
            return parseInt(this.admin.timeout) > 0;
        },
        isHaveAdmin() {
            return !empty(this.admin);
        },
        isHeaderShortcuts() {
            return !empty(this.headerShortcuts) && this.headerShortcuts.length > 0;
        },
        ...mapGetters(['admin', 'headerShortcuts']),
        ...mapGetters('search', [
            'searchEntities',
        ]),
        ...mapGetters('notifications', [
            'notificationsList',
        ]),
        ...mapGetters('on_boarding', [
            'showDeploymentGuideButton',
        ]),
        ...mapGetters('menu_store', [
            'mobileWidth',
        ]),
    },
    components: {},
};

</script>

<style scoped lang="scss">
#notification-list {
    display: none;
}
</style>
