import { SET_VOICE_PAGE_TARIFFS_FOR_CHANGE, SET_VOICE_TARIFFS_PAGE_INFO } from '@/apps/admin/store/mutation-types';
import { isset } from '@/utils/functions';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    model: {},
    className: '',
    attributesMeta: {},
    afAttributesMeta: {},
    customerLabels: {},
    typesArray: {},
    taxesArray: {},
    tariffsForChange: {},
    billingTypes: {},
    transactionsCategories: {},
    transactionsCategoriesArrayForItems: {},
    isSetTransactionCategory: false,
    customerCategories: {},
    customPeriod: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadAddPageInfo({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/tariffs/voice--get-form-page-info',
                success: (response) => {
                    commit(SET_VOICE_TARIFFS_PAGE_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    loadEditPageInfo({ commit }, id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/tariffs/voice--get-form-page-info?id=${id}`,
                success: (response) => {
                    commit(SET_VOICE_TARIFFS_PAGE_INFO, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

const mutations = {
    [SET_VOICE_TARIFFS_PAGE_INFO](state, info) {
        state.typesArray = info.typesArray;
        state.taxesArray = info.taxesArray;
        state.tariffsForChange = info.tariffsForChange;
        state.customerLabels = info.customerLabels;
        state.billingTypes = info.billingTypes;
        state.transactionsCategories = info.transactionsCategories;
        state.transactionsCategoriesArrayForItems = info.transactionsCategoriesArrayForItems;
        state.isSetTransactionCategory = info.isSetTransactionCategory;
        state.className = info.attributesMeta.className;
        state.attributesMeta = info.attributesMeta.attributes;
        state.customerCategories = info.customerCategories;
        state.customPeriod = info.customPeriod;
        if (isset(info, ['attributesMeta', 'additional_attributes'])) {
            state.afAttributesMeta = info.attributesMeta.additional_attributes;
        }
        if (isset(info, 'model')) {
            state.model = info.model;
        }
    },
    [SET_VOICE_PAGE_TARIFFS_FOR_CHANGE](state, tariffsForChange) {
        state.tariffsForChange = tariffsForChange;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
