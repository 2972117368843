const LogsOperationsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/operations/LogsOperationsList');
const InternalLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/internal/InternalLogsList');
const PortalLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/portal/PortalLogsList');
const FilesLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/files/FilesLogsList');
const EmailLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/email/EmailLogsList');
const SmsLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/sms/SmsLogsList');
const SessionsLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/sessions/SessionsLogsList');
const ApiLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/api/ApiLogsList');
const ChangesLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/changes/ChangesLogsList');
const PendingLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/pending/PendingLogsList');
const AccountingLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/accounting/AccountingLogsList');
const EInvoicingLogsList = () => import(/* webpackChunkName: "logs" */ '@/apps/admin/views/admin/administration/logs/e-invoicing/EInvoicingLogsList');

export default [
    {
        path: 'operations',
        name: 'admin.administration.logs.operations',
        component: LogsOperationsList,
        meta: {
            keepAlive: true,
            componentName: 'LogsOperationsList',
        },
    },
    {
        path: 'internal',
        name: 'admin.administration.logs.internal',
        component: InternalLogsList,
        meta: {
            keepAlive: true,
            componentName: 'InternalLogsList',
        },
    },
    {
        path: 'portal',
        name: 'admin.administration.logs.portal',
        component: PortalLogsList,
        meta: {
            keepAlive: true,
            componentName: 'PortalLogsList',
        },
    },
    {
        path: 'files',
        name: 'admin.administration.logs.files',
        component: FilesLogsList,
        meta: {
            keepAlive: true,
            componentName: 'FilesLogsList',
        },
    },
    {
        path: 'email',
        name: 'admin.administration.logs.email',
        component: EmailLogsList,
        meta: {
            keepAlive: true,
            componentName: 'EmailLogsList',
        },
    },
    {
        path: 'sms',
        name: 'admin.administration.logs.sms',
        component: SmsLogsList,
        meta: {
            keepAlive: true,
            componentName: 'SmsLogsList',
        },
    },
    {
        path: 'sessions',
        name: 'admin.administration.logs.sessions',
        component: SessionsLogsList,
        meta: {
            keepAlive: true,
            componentName: 'SessionsLogsList',
        },
    },
    {
        path: 'api',
        name: 'admin.administration.logs.api',
        component: ApiLogsList,
        meta: {
            keepAlive: true,
            componentName: 'ApiLogsList',
        },
    },
    {
        path: 'changes',
        name: 'admin.administration.logs.changes',
        component: ChangesLogsList,
        meta: {
            keepAlive: true,
            componentName: 'ChangesLogsList',
        },
    },
    {
        path: 'pending',
        name: 'admin.administration.logs.pending',
        component: PendingLogsList,
        meta: {
            keepAlive: true,
            componentName: 'PendingLogsList',
        },
    },
    {
        path: 'accounting',
        name: 'admin.administration.logs.accounting',
        component: AccountingLogsList,
        meta: {
            keepAlive: true,
            componentName: 'AccountingLogsList',
        },
    },
    {
        path: 'e-invoicing',
        name: 'admin.administration.logs.e_invoicing',
        component: EInvoicingLogsList,
        meta: {
            keepAlive: true,
            componentName: 'EInvoicingLogsList',
        },
    },
];
