import VueRouter from 'vue-router';
import { flatRoutes } from '@/utils/router/flat-routes';
import { mergeBreadcrumbs } from '@/utils/router/merge-breadcrumbs';
import LicenseChecker from '@/apps/admin/services/license';
import NotFoundView from '../views/NotFoundView';
import AdminRoutes from './routes/admin';

const routes = flatRoutes(mergeBreadcrumbs([
    {
        path: '/',
        redirect: (to) => {
            to({ name: 'admin.login' });
        },
    },
    {
        path: '/admin',
        children: AdminRoutes,
        props: true,
    },
    {
        name: 'not_found',
        meta: {
            layout: 'empty_layout',
        },
        // not found handler
        // will match everything and put it under `$route.params.pathMatch`
        path: '/:pathMatch(.*)*',
        component: NotFoundView,
    },
]));

// @see https://github.com/vuejs/vue-router/issues/2881
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    window.popStateDetected = false;
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    let licenseChecker = new LicenseChecker();
    if (licenseChecker.needRedirectToLicenseRoute(to.fullPath)) {
        next(licenseChecker.getRouteToRedirect());
    }

    next();
});

window.addEventListener('popstate', () => {
    window.popStateDetected = true;
});

router.beforeEach((to, from, next) => {
    const container = '#content, .splynx-dialog-content, .vue-dialog-body';
    if (isHaveUnsavedData(container)) {
        next(false);
        window.showUnsavedDataDialog(container, () => {
            if (window.popStateDetected) {
                router.go(-1);
                return false;
            }
            next();
        }, () => {
            next(false);
        });
        return true;
    }
    next();
});

export default router;
