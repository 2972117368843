import XTwigPage from '@/components/base/XTwigPage';

export default [
    {
        path: 'import',
        name: 'admin.config.tools.import',
        component: XTwigPage,
    },
    {
        path: 'import--new',
        name: 'admin.config.tools.import.new',
        component: XTwigPage,
    },
    {
        path: 'import--set-columns',
        name: 'admin.config.tools.import.set_columns',
        component: XTwigPage,
    },
    {
        path: 'import--confirm-import',
        name: 'admin.config.tools.import.confirm',
        component: XTwigPage,
    },
    {
        path: 'import--summary',
        name: 'admin.config.tools.import.summary',
        component: XTwigPage,
    },
    {
        path: 'export',
        name: 'admin.config.tools.export',
        component: XTwigPage,
    },
    {
        path: 'activate-services',
        name: 'admin.config.tools.activate_services',
        component: XTwigPage,
    },
    {
        path: 'cloud',
        name: 'admin.config.tools.cloud',
        component: XTwigPage,
    },
    {
        path: 'invoices-cache',
        name: 'admin.config.tools.invoices_cache',
        component: XTwigPage,
    },
    {
        path: 'backup-and-restore',
        name: 'admin.config.tools.backup_and_restore',
        component: XTwigPage,
    },
    {
        path: 'backup-and-restore--upload-detail',
        name: 'admin.config.tools.backup_and_restore.upload',
        component: XTwigPage,
    },
    {
        path: 'backup-and-restore--create-detail',
        name: 'admin.config.tools.backup_and_restore.create',
        component: XTwigPage,
    },
    {
        path: 'backup-and-restore--restore-detail',
        name: 'admin.config.tools.backup_and_restore.restore_detail',
        component: XTwigPage,
    },
    {
        path: 'update-coordinates',
        name: 'admin.config.tools.update_coordinates',
        component: XTwigPage,
    },
    {
        path: 'customer-restore',
        name: 'admin.config.tools.customer_restore',
        component: XTwigPage,
    },
    {
        path: 'migrate-services',
        name: 'admin.config.tools.migrate_services',
        component: XTwigPage,
    },
];
