<template>
    <x-view-error
        v-if="showPageError.error"
        application="admin"
        :error="showPageError"
    />
    <empty-layout v-else-if="$route.meta.layout === LAYOUT_EMPTY">
        <router-view
            :key="routeViewKey"
        />
    </empty-layout>
    <base-layout v-else>
        <router-view
            v-if="!$route.meta.keepAlive"
            :key="routeViewKey"
        />
        <keep-alive
            :max="20"
            :exclude="exclude"
        >
            <router-view
                v-if="!refreshing && $route.meta.keepAlive"
                ref="routerViews"
                :key="routeViewKey"
            />
        </keep-alive>
    </base-layout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BaseLayout from '@/components/layouts/BaseLayout';
import EmptyLayout from '@/components/layouts/EmptyLayout';
import { LAYOUT_EMPTY } from '@/constants';
import XViewError from '@/components/base/XViewError/XViewError';
import initOnShowModals from './on-show-modals';
import {
    SET_MENU,
    SET_ADMIN,
    SET_HEADER_SHORTCUTS,
    SET_LOGO,
    SET_FOOTER,
    SET_SEARCH_ENTITIES,
    SET_SERVER_SIDE_CONFIG,
    SET_LICENSE,
    SET_PAGE_TITLE,
    SET_DOCUMENTATION_CONFIGS,
    SET_NUMBER_CONFIG,
    SET_ON_BOARDING_STEPS, SET_APP_CHILD_REFERENCES,
} from './store/mutation-types';

const DEFAULT_EXCLUDE = 'XTwigPage';

export default {
    name: 'App',
    data() {
        return {
            routeViewKey: this.$route.fullPath,
            LAYOUT_EMPTY,
            refreshing: false,
            exclude: DEFAULT_EXCLUDE,
        };
    },
    beforeMount() {
        this.SET_MENU(spl_config.menuStructure);
        this.SET_ADMIN(spl_config.admin);
        this.SET_HEADER_SHORTCUTS(spl_config.headerShortcuts);
        this.SET_LOGO(spl_config.header);
        this.SET_FOOTER(spl_config.footer);
        this.SET_LICENSE(spl_config.license);
        this.$store.commit(`search/${SET_SEARCH_ENTITIES}`, spl_config.search_entities);
        this.SET_SERVER_SIDE_CONFIG(spl_config.serverSideConfig);
        this.SET_DOCUMENTATION_CONFIGS(spl_config.documentationConfig);
        this.SET_NUMBER_CONFIG(spl_config.numberConfig);
        this.SET_ON_BOARDING_STEPS(spl_config.onBoardingSteps);
        this.SET_APP_CHILD_REFERENCES(this.$refs);
        this.setTitle(this.$route);
    },
    mounted() {
        this.$router.beforeEach((to, from, next) => {
            if (from.fullPath !== to.fullPath) {
                splynx_event_bus.emit('before_switch_page');

                checkUnsavedDataBeforeLeavePage(() => {
                    next();
                }, () => {
                    next(false);
                });
            }
        });
        this.$router.afterEach((to) => {
            this.setTitle(to);

            removeWebUiPopovers();

            if (!this.showNotFoundPage) {
                window.closeAllVueDialogs();
            }

            this.$nextTick(() => {
                splynx_event_bus.emit('after_switch_page');
                this.openModalFromUrl();
            });
        });

        initOnShowModals(this);

        // Show warning after Splynx upgrade
        add_socket_listener('after_system_upgrade', () => {
            let msg = `${this.$t('common', 'System has been updated. The page may not work correctly.')
            } <br><b>${this.$t('common', 'Please refresh the page')}</b><br>`;
            msg += `<button class="btn btn-primary" style="margin-top: 5px;" onclick="location.reload()">${
                this.$t('common', 'Refresh')}</button>`;
            show_warning(msg, 9999);
        });
    },
    methods: {
        ...mapMutations([
            SET_MENU, SET_ADMIN, SET_HEADER_SHORTCUTS, SET_LOGO, SET_LICENSE,
            SET_SERVER_SIDE_CONFIG, SET_PAGE_TITLE, SET_DOCUMENTATION_CONFIGS,
            SET_FOOTER, SET_NUMBER_CONFIG,
            SET_ON_BOARDING_STEPS, SET_APP_CHILD_REFERENCES,
        ]),
        setTitle(route) {
            if (isset(route.meta, 'title')) {
                let { title } = route.meta;
                // Translate page title
                let translateOfTitle = this.$t(title[0], title[1], title.length === 3 ? title[2] : null);
                this.SET_PAGE_TITLE(translateOfTitle);
            } else {
                this.SET_PAGE_TITLE('');
            }
        },
        openModalFromUrl() {
            let { query } = this.$route;
            if (query.modal === undefined) {
                return;
            }

            if (query['modal-type'] == 2) {
                open_dialog_new(query.modal);
                return;
            }

            let modalParams = {};
            Object.keys(query).forEach((key) => {
                let match = key.match(/modal-params\[(.*)\]/);
                if (match !== null) {
                    modalParams[match[1]] = query[key];
                }
            });

            showModal(query.modal, modalParams);
        },
    },
    computed: {
        ...mapGetters(['refreshRouteKey', 'showPageError']),
    },
    watch: {
        '$route.fullPath': function () {
            this.routeViewKey = this.$route.fullPath.split(/[?, #]+/)[0];
        },
        refreshRouteKey() {
            if (this.$route.meta.keepAlive !== true) {
                this.routeViewKey = this.refreshRouteKey;
            }
            // Refreshing route
            this.exclude = this.$route.meta.componentName;
            this.refreshing = true;
            setTimeout(() => {
                this.refreshing = false;
                this.$nextTick(() => {
                    this.exclude = DEFAULT_EXCLUDE;
                });
            }, 200);
        },
    },
    components: {
        XViewError,
        EmptyLayout,
        BaseLayout,
    },
};
</script>

<style lang="scss">
  @import '../../design/main';
  @import '../../design/admin';
</style>
