<template>
    <div class="sidebar-content-inner">
        <div class="search-wrapper">
            <div class="input-group">
                <input
                    ref="searchInput"
                    v-model="inputSearchQuery"
                    type="text"
                    autocomplete="off"
                    class="fast_search_in form-control search-input search"
                    :placeholder="$t('common', 'Search...')"
                    autofocus
                >

                <button
                    v-if="ifIsEmptySearchQuery"
                    type="button"
                    :class="['btn', 'btn-outline-secondary', 'btn-icon']"
                >
                    <i
                        v-if="isLoading"
                        class="icon-ic_fluent_spinner_ios_20_regular icon-spin"
                    />
                    <i
                        v-else
                        class="icon-ic_fluent_search_24_regular"
                    />
                </button>
                <button
                    v-else
                    type="button"
                    :class="['btn', 'btn-outline-secondary', 'btn-icon']"
                    @click="clearSearchQuery()"
                >
                    <i
                        v-if="isLoading"
                        class="icon-ic_fluent_spinner_ios_20_regular icon-spin"
                    />
                    <i
                        v-else
                        class="icon-ic_fluent_dismiss_24_regular"
                    />
                </button>
            </div>
        </div>
        <div
            ref="searchList"
            class="search-list"
        >
            <x-global-search-offers
                v-if="!ifSearchIsEmpty"
                :filtered-search-entity="filteredSearchEntity"
                :choice-entity="choiceEntity"
            />

            <div v-if="searchResultsEmptyObject && ifSearchIsEmpty">
                <div class="help-message">
                    <h4>{{ $t('common', 'Tip') }}</h4>
                    <p>
                        <x-translate
                            tag="false"
                            category="common"
                            message="Use the {forward_slash} key or {s_key} key at any time to open this window"
                        >
                            <template #forward_slash>
                                <code>/</code>
                            </template>
                            <template #s_key>
                                <code>s</code>
                            </template>
                        </x-translate>
                    </p>
                    <br>
                    <p>
                        <x-translate
                            tag="false"
                            category="common"
                            message="To show all the available entities to search through, type {symbol} sign in a search field."
                        >
                            <template #symbol>
                                <code>@</code>
                            </template>
                        </x-translate>
                    </p>
                    <br>
                    <p>
                        <x-translate
                            tag="false"
                            category="common"
                            message="To start the search in the desired entity, type {entityPattern}, e.g. {entityExample}."
                        >
                            <template #entityPattern>
                                <code>@entity_name: data</code>
                            </template>
                            <template #entityExample>
                                <code>@invoices: 202101000004</code>
                            </template>
                        </x-translate>
                    </p>
                    <br>
                    <p>
                        <x-translate
                            tag="false"
                            category="common"
                            message="To search according to id, start a search request from {sharp} sign, then add id num, e.g. {sharpExample}."
                        >
                            <template #sharp>
                                <code>#</code>
                            </template>
                            <template #sharpExample>
                                <code>#123</code>
                            </template>
                        </x-translate>
                    </p>
                    <br>
                    <p>
                        <x-translate
                            tag="false"
                            category="common"
                            message="The combination of the methods above for search request is supported, e.g. {example}."
                        >
                            <template #example>
                                <code>@customers: #40</code>
                            </template>
                        </x-translate>
                    </p>
                </div>
            </div>
            <hr v-if="!ifSearchIsEmpty">
            <div
                v-if="!searchResults.isEmpty"
                class="fast-search-results-block"
            >
                <table id="fast_search_result">
                    <tr
                        v-for="(item, index) in searchResults.items"
                        :key="'result_item' + index"
                        @click="callHrefFunction(item)"
                    >
                        <td :class="{'bg-light-warning': index === activePosition}">
                            <div class="search-heading">
                                <div class="icon-wrap-sm">
                                    <i
                                        :class="{[item.icon]: true, 'result-icon': true}"
                                        :title="item.iconTitle"
                                    />
                                </div>
                                <a
                                    :href="getHref(item)"
                                    class="result-heading"
                                    @click.prevent
                                    v-text="item.title"
                                />
                            </div>
                            <div
                                v-if="item.relationLink "
                                class="result-relationLink"
                                v-text="item.relationLink"
                            />
                            <div
                                class="result-sniped"
                                v-html="item.snippet"
                            />
                        </td>
                    </tr>
                </table>
            </div>
            <div v-else>
                <br>
                <h4
                    style="text-align: center"
                    v-text="t('common', 'No results found.')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { debounce, empty } from '@/utils/functions';
import { mapActions, mapGetters } from 'vuex';
import XTranslate from '@/splang/XTranslate';
import XGlobalSearchOffers from './XGlobalSearchOffers';

export default {
    name: 'XGlobalSearch',
    delimiters: ['${', '}'],
    props: {
        availableSearchEntityList: [Array, Object],
        appMountedCallback: Function,
        appDestroyedCallback: Function,
        openSearch: Function,
    },
    data() {
        return {
            skipSearchQuery: false,
            inputSearchQuery: this.$store.getters['global_search/searchQuery'],
            isLoading: false,
            debouncedSearch: debounce(this.search, 300),
        };
    },
    computed: {
        searchResultsEmptyObject() {
            return Object.keys(this.searchResults).length <= 0 || this.searchResults === undefined || this.searchResults === null;
        },
        filteredSearchEntity() {
            if (empty(this.inputSearchQuery) || this.inputSearchQuery.charAt(0) !== '@') {
                return [];
            }

            // scroll to entity list
            this.scrollResultsList(0);

            return this.availableSearchEntityList.filter((entity) => entity.toLowerCase().includes(this.inputSearchQuery.toLowerCase()));
        },
        ifSearchIsEmpty() {
            return empty(this.filteredSearchEntity);
        },
        ifIsEmptySearchQuery() {
            return empty(this.searchQuery);
        },
        searchQuery: {
            get() {
                return this.$store.getters['global_search/searchQuery'];
            },
            set(value) {
                this.debouncedSearch(value);
            },
        },
        ...mapGetters('global_search', [
            'searchResults',
            'activePosition',
        ]),
    },
    watch: {
        inputSearchQuery(value) {
            if (this.skipSearchQuery) {
                this.skipSearchQuery = false;
                return;
            }

            if (value === null || value === '') {
                this.clearSearchQuery();
                return;
            }

            if (value.length < 2) {
                return;
            }

            this.searchQuery = value;
        },
    },
    methods: {
        empty(el) {
            return empty(el);
        },
        hideSearchSidebar() {
            this.hideSidebar();
        },
        clearSearchQuery() {
            this.clearSearch();
            this.inputSearchQuery = '';
            this.setFocusIntoSearchInput();
        },
        getHref(item) {
            if (item.modalInfo === null) {
                return item.href;
            }

            let { path } = this.$route;
            if (!empty(this.$route.query)) {
                path += `?${$.param({ ...this.$route.query, ...item.modalInfo })}`;
            }
            return path;
        },
        callHrefFunction(item) {
            setTimeout(() => {
                if (item.modalInfo !== null) {
                    if (item.modalInfo['modal-type'] == 2) {
                        open_dialog_new(item.modalInfo.modal);
                    } else {
                        showModal(item.modalInfo.modal, item.modalInfo['modal-params']);
                    }
                } else {
                    (new Function(`switch_page("${item.href}")`))();
                }
            }, 200); // DO NOT REMOVE THIS TIMEOUT THIS HACK FIX OPEN MODALS BY ENTER BUTTON

            this.hideSearchSidebar();
        },
        processKeyboard(e) {
            if (!empty(this.filteredSearchEntity)) {
                return;
            }

            if (e.keyCode === 13 && this.searchResults.items) {
                if (this.searchResults.items[this.activePosition] === undefined) {
                    return;
                }

                this.callHrefFunction(this.searchResults.items[this.activePosition]);
            }

            if (e.keyCode === 38 || e.keyCode === 40) {
                e.preventDefault();
                this.processSelectionByKeyboard(e.keyCode);
                return false;
            }
        },
        scrollResults() {
            let all = 0;
            for (let i = 0; i < this.activePosition - 1; i++) {
                let target = $('#fast_search_result:visible tr').eq(i);
                let z = target.height();
                all += z;
            }

            this.scrollResultsList(all);
        },
        processSelectionByKeyboard(keyCode) {
            this.updateActivePositionByKey(keyCode);
            this.scrollResults();
        },
        choiceEntity(entity) {
            if (entity !== undefined) {
                this.skipSearchQuery = true;
                this.inputSearchQuery = `${entity}: `;
                this.setFocusIntoSearchInput();
            }
        },
        setFocusIntoSearchInput() {
            let $input = $(this.$refs.searchInput);
            if ($input.is(':visible')) {
                this.$refs.searchInput.focus();
                return;
            }

            let timerId = setInterval(() => {
                if ($input.is(':visible')) {
                    this.$refs.searchInput.focus();
                    clearInterval(timerId);
                }
            }, 10);
        },
        scrollResultsList(top) {
            this.$refs.searchList?.scrollTo({
                top,
                behavior: 'smooth',
            });
        },
        ...mapActions('sidebar', [
            'hideSidebar',
        ]),
        ...mapActions('global_search', [
            'search',
            'clearSearch',
            'updateActivePositionByKey',
        ]),
    },
    mounted() {
        this.appMountedCallback(this);
        this.scrollResults();

        splynx_event_bus.reinitEvent('globalSearchProcessingStart', () => {
            this.isLoading = true;
        });
        splynx_event_bus.reinitEvent('globalSearchProcessingStop', () => {
            this.isLoading = false;
        });
    },
    destroyed() {
        this.appDestroyedCallback(this);
    },
    components: {
        XTranslate,
        XGlobalSearchOffers,
    },
};
</script>
