import XTwigPage from '@/components/base/XTwigPage';

const InventoryStocksList = () => import(/* webpackChunkName: "inventory_config" */ '@/apps/admin/views/admin/config/inventory/stocks/InventoryStocksList');

export default [
    {
        path: 'stocks',
        name: 'admin.config.inventory.stocks',
        component: InventoryStocksList,
        meta: {
            keepAlive: true,
            componentName: 'InventoryStocksList',
        },
    },
    {
        path: 'notifications',
        name: 'admin.config.inventory.notifications',
        component: XTwigPage,
    },
];
