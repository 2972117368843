import XTwigPage from '@/components/base/XTwigPage';
import SupplyRoutes from './inventory/supply';
import ReportsRoutes from './inventory/reports';

const InventoryDashboard = () => import(/* webpackChunkName: "inventory_dashboard" */ '@/apps/admin/views/admin/inventory/dashboard/InventoryDashboard');
const InventoryItemsList = () => import(/* webpackChunkName: "inventory_items" */ '@/apps/admin/views/admin/inventory/items/InventoryItemsList');
const InventoryProductsList = () => import(/* webpackChunkName: "inventory_products" */ '@/apps/admin/views/admin/inventory/products/InventoryProductsList');

export default [
    {
        path: 'dashboard',
        name: 'admin.inventory.dashboard',
        component: InventoryDashboard,
        meta: {
            keepAlive: true,
            componentName: 'InventoryDashboard',
        },
    },
    {
        path: 'items',
        name: 'admin.inventory.items',
        component: InventoryItemsList,
        meta: {
            keepAlive: true,
            componentName: 'InventoryItemsList',
        },
    },
    {
        path: 'items--history',
        name: 'admin.inventory.items.history',
        component: XTwigPage,
    },
    {
        path: 'products',
        name: 'admin.inventory.products',
        component: InventoryProductsList,
        meta: {
            keepAlive: true,
            componentName: 'InventoryProductsList',
        },
    },
    {
        path: 'products--view',
        name: 'admin.inventory.products.view',
        component: XTwigPage,
    },
    {
        path: 'supply',
        children: SupplyRoutes,
    },
    {
        path: 'reports',
        children: ReportsRoutes,
    },
];
