import {
    INVENTORY_ITEMS_SELL_DELETE_ITEM,
    INVENTORY_ITEMS_SELL_INIT,
    INVENTORY_ITEMS_SELL_SET_FINANCE_ITEMS,
    INVENTORY_ITEMS_SELL_SET_ITEMS,
    INVENTORY_ITEMS_SELL_SET_TOTALS,
    INVENTORY_ITEMS_SELL_UPDATE_ITEM,
    INVENTORY_ITEMS_SELL_UPDATE_ROW_STATE,
    INVENTORY_ITEMS_SELL_SET_IDS,
} from '@/apps/admin/store/mutation-types';

import { stateToGetters } from '@/apps/admin/store/helpers';
import Vue from 'vue';
import { sendFormData } from '@/utils/senders';
import { sortObjectByProperty } from '@/utils/functions';

const state = {
    taxName: null,
    items: {},
    ids: '',
    invoice: {},
    customerId: 0,
    taxTotal: 0,
    totalWithoutTax: 0,
    totalWithTax: 0,
};

const getters = {
    ...stateToGetters(state),
    formData(state) {
        let formData = new FormData();
        formData.set('customer_id', state.customerId);
        for (let key in state.items) {
            if (!Object.prototype.hasOwnProperty.call(state.items, key)) {
                continue;
            }

            const item = state.items[key];
            formData.append('id[]', item.id);
            formData.append('categoryIdForTransaction[]', item.categoryIdForTransaction);
            formData.append('inventoryItems[]', item.inventoryItems);
            formData.append('pos[]', item.pos);
            formData.append('description[]', item.description);
            formData.append('quantity[]', item.quantity);
            formData.append('unit[]', item.unit !== null ? item.unit : '');
            formData.append('price[]', item.price);
            formData.append('tax_percent[]', item.tax);
            formData.append('with_tax[]', item.with_tax);
        }

        formData.append('number', state.invoice.number);
        formData.append('date_created', state.invoice.date_created);
        formData.append('date_till', state.invoice.date_till);
        formData.append('note', state.invoice.note);

        formData.set('ids', state.ids);
        return formData;
    },
};

const actions = {
    initForm({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/inventory/items--sell-modal-info',
                success(response) {
                    commit(INVENTORY_ITEMS_SELL_INIT, response);
                    resolve();
                },
                failed() {
                    reject();
                },
            });
        });
    },
    loadFinanceItemsList({ commit }, data) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/inventory/items--sell-modal-info-form-data?customerId=${data.customerId}`,
                method: 'POST',
                data: {
                    ids: data.ids,
                },
                success(response) {
                    commit(INVENTORY_ITEMS_SELL_SET_FINANCE_ITEMS, response);
                    commit(INVENTORY_ITEMS_SELL_SET_IDS, data.ids);
                    resolve();
                },
                failed() {
                    reject();
                },
            });
        });
    },
    recalculateRow({ state, commit }, rowData) {
        let { item } = rowData;
        let { modifiedField } = rowData;

        let data = [];
        let rowIndex = 0;
        for (let key in state.items) {
            if (!Object.prototype.hasOwnProperty.call(state.items, key)) {
                continue;
            }

            let row = state.items[key];
            if (item.product_id === key) {
                row = item;
            }

            data[rowIndex++] = {
                quantity: row.quantity,
                price: row.price,
                vat: row.tax,
                withVat: row.with_tax,
                isChangedInput: parseInt(item.product_id) === parseInt(row.product_id),
            };
        }

        $.ajax({
            url: `/admin/inventory/items--calc-row?quantity=${item.quantity}&price=${item.price}&vat=${item.tax}&withVat=${item.with_tax}&modifiedField=${modifiedField}`,
            type: 'POST',
            data: { items: JSON.stringify(data) },
            success(response) {
                commit(INVENTORY_ITEMS_SELL_SET_TOTALS, {
                    taxTotal: response.total.vat,
                    totalWithoutTax: response.total.withoutVat,
                    withTax: response.total.total,
                });

                item.price = response.rowData.price;
                item.quantity = response.rowData.quantity;
                item.total = response.rowData.total;
                item.tax = response.rowData.vat;
                item.with_tax = response.rowData.withVat;

                commit(INVENTORY_ITEMS_SELL_UPDATE_ITEM, {
                    product_id: item.product_id,
                    item,
                });
            },
            error() {
                show_error(window.xApp.$splang.t('inventory', 'Error while recalculate totals'), 4);
            },
        });
    },
    clearFormData({ commit }) {
        commit(INVENTORY_ITEMS_SELL_SET_FINANCE_ITEMS, {
            formData: {
                items: [],
                invoice: {
                    number: '',
                    date_created: '',
                    date_till: '',
                },
                invoiceTotals: {
                    totalVat: 0,
                    totalWithoutVat: 0,
                    total: 0,
                },
            },
        });
    },
    addRow({ state }) {
        let item = {};
        let index = Math.max.apply(null, Object.keys(state.items)) + 1;
        item.description = '';
        item.categoryIdForTransaction = 1;
        item.pos = index;
        item.product_id = index;
        item.price = '0.0000';
        item.with_tax = '0.0000';
        item.total = '0.0000';
        item.quantity = 1;
        item.isNew = 1;
        item.tax = '0.00';
        item.getTaxesArray = state.items[Object.keys(state.items)[0]].getTaxesArray;
        Vue.set(state.items, index, item);
    },
    removeRow({ state, commit, dispatch }, productId) {
        if (Object.keys(state.items).length <= 1) {
            return;
        }

        commit(INVENTORY_ITEMS_SELL_DELETE_ITEM, productId);

        // Sort object with removed item and fix positions.
        let sortedItems = sortObjectByProperty(state.items, 'pos', false, true);
        sortedItems = Array.from(sortedItems.values());
        dispatch('rePos', sortedItems);

        // Recalculate totals after removing item
        dispatch('recalculateRow', {
            item: state.items[Object.keys(state.items)[Object.keys(state.items).length - 1]],
            modifiedField: 'price',
        });
    },
    rePos({ state, commit }, sortedArray) {
        let { items } = state;

        for (let key in sortedArray) {
            if (!Object.prototype.hasOwnProperty.call(sortedArray, key)) {
                continue;
            }

            const itemFromSortedArray = sortedArray[key];
            if (Object.prototype.hasOwnProperty.call(items, itemFromSortedArray.product_id)) {
                items[itemFromSortedArray.product_id].pos = parseInt(key) + 1;
            }
        }

        commit(INVENTORY_ITEMS_SELL_SET_ITEMS, items);
    },
    createDocument({ getters }) {
        return sendFormData('/admin/inventory/items--add-invoice', getters.formData);
    },
    getNewNumber(obj, { customer_id, date_created }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/invoices--number?customer_id=${customer_id}`,
                method: 'GET',
                data: {
                    date_created,
                },
                success: (response) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [INVENTORY_ITEMS_SELL_INIT](state, data) {
        state.taxName = data.taxName;
    },
    [INVENTORY_ITEMS_SELL_SET_FINANCE_ITEMS](state, data) {
        state.items = data.formData.items;
        state.invoice = data.formData.invoice;
        state.customerId = data.customerId;

        state.taxTotal = data.formData.invoiceTotals.totalVat;
        state.totalWithoutTax = data.formData.invoiceTotals.totalWithoutVat;
        state.totalWithTax = data.formData.invoiceTotals.total;
    },
    [INVENTORY_ITEMS_SELL_SET_TOTALS](state, data) {
        state.taxTotal = data.taxTotal;
        state.totalWithoutTax = data.totalWithoutTax;
        state.totalWithTax = data.withTax;
    },
    [INVENTORY_ITEMS_SELL_UPDATE_ROW_STATE](state, data) {
        state.updatingRow = data;
    },
    [INVENTORY_ITEMS_SELL_UPDATE_ITEM](state, data) {
        Vue.set(state.items, data.product_id, data.item);
    },
    [INVENTORY_ITEMS_SELL_DELETE_ITEM](state, product) {
        Vue.delete(state.items, product);
    },
    [INVENTORY_ITEMS_SELL_SET_ITEMS](state, items) {
        state.items = items;
    },
    [INVENTORY_ITEMS_SELL_SET_IDS](state, ids) {
        state.ids = ids;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
